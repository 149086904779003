import { Component, FC } from 'react';
import { BarChart, Bar, CartesianGrid, ResponsiveContainer, XAxis, YAxis, LabelList, Tooltip, Legend } from 'recharts';

interface AnalyticsBarChartProps {
  value: string | number;
  segments: any;
  displayOptions?: any;
}

const formatData = (segments: [] | undefined, indexedOptions: any | undefined): any[] => {
  const res: any[] = [];
  if (segments) {
    segments.forEach((segment:any) => {
        let label = segment.name;
        let id = '';
        if (indexedOptions && indexedOptions[segment.name]) {
          label = indexedOptions[segment.name].display;
          id = indexedOptions[segment.name].id;
        }
        res.push({
          display: label,
          value:  id,
          RED: segment.RED,
          ORANGE: segment.ORANGE,
          YELLOW: segment.YELLOW,
          GREEN: segment.GREEN
        })
    });
  }

  return res.sort((a, b) => a.value - b.value);
}

const legendData:any = [
  { value: 'Satisfactorio', text: 'Existen acciones que están fomentando un ambiente alimentario escolar saludable. Es recomendable compartir con otros acerca de qué y cómo lo han hecho para ayudar a sostener estas acciones en comunidad.', color: '#56B448' },
  { value: 'Medianamente satisfactorio', text: 'Se están fomentando acciones para un ambiente alimentario escolar saludable; sin embargo, aún existen áreas para seguir trabajando. Se requiere revisar, en comunidad, la manera más óptima para mejorar.', color: '#FFD700' },
  { value: 'Poco satisfactorio', text: 'Existen algunas acciones con las que se está fomentando un ambiente alimentario escolar saludable, pero aún no son suficientes.  Se requiere revisar, en comunidad, la manera más óptima para mejorar.', color: '#FC9416' },
  { value: 'Nada satisfactorio', text: 'Existen muy pocas o nulas acciones que fomentan un ambiente alimentario escolar saludable, se requiere revisar en comunidad, la manera más óptima para iniciar dichas acciones.', color: '#E33945' },
];

const getIcon = (value: string) => {
  let icon;
  switch (value) {
    case '0':
      icon = (
        <svg width="62" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <text x="0" y="58%" alignmentBaseline="middle" fontSize="12" letterSpacing="1" fill="black">TOTAL</text>
        </svg>
      );
      break;
    case '1':
      icon = (
        <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 14m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
          <path d="M12 11v-6a2 2 0 0 1 2 -2h2v1a2 2 0 0 1 -2 2h-2"></path>
          <path d="M10 10.5c1.333 .667 2.667 .667 4 0"></path>
        </svg>
      );
      break;
    case '2':
      icon = (
        <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
          <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
          <path d="M3 6l0 13"></path>
          <path d="M12 6l0 13"></path>
          <path d="M21 6l0 13"></path>
        </svg>
      );
      break;
    case '3':
      icon = (
        <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
          <path d="M14.473 17.659a8.897 8.897 0 0 1 -2.473 .341c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
          <path d="M19 16v3"></path>
          <path d="M19 22v.01"></path>
        </svg>
      );
      break;
    case '4':
      icon = (
        <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M3 21v-13l9 -4l9 4v13"></path>
          <path d="M13 13h4v8h-10v-6h6"></path>
          <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3"></path>
        </svg>
      );
      break;
    case '5':
      icon = (
        <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M16 6m-5 0a5 3 0 1 0 10 0a5 3 0 1 0 -10 0"></path>
          <path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
          <path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
          <path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
          <path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path>
          <path d="M5 15v1m0 -8v1"></path>
        </svg>
      );
      break;
    case '6':
      icon = (
        <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
          <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path>
          <path d="M6 14m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
          <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path>
        </svg>
      );
      break;
    default:
      icon = null;
      break;
  }
  return <div style={{display:'flex', justifyContent:'center', marginBottom: '12px'}}>{icon}</div>;
};

class CustomAxis extends Component<any> {
  getIcon() {
    const { x, y, payload } = this.props;
    let icon;
    switch (payload.value) {
      case '0':
        icon = (
          <svg x={x - 36} y={y} width={58} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <text x="0" y="58%" alignmentBaseline="middle" fontSize="12" letterSpacing="1" fill="black">TOTAL</text>
          </svg>
        );
        break;
      case '1':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 14m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
            <path d="M12 11v-6a2 2 0 0 1 2 -2h2v1a2 2 0 0 1 -2 2h-2"></path>
            <path d="M10 10.5c1.333 .667 2.667 .667 4 0"></path>
          </svg>
        );
        break;
      case '2':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
            <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
            <path d="M3 6l0 13"></path>
            <path d="M12 6l0 13"></path>
            <path d="M21 6l0 13"></path>
          </svg>
        );
        break;
      case '3':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
            <path d="M14.473 17.659a8.897 8.897 0 0 1 -2.473 .341c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
            <path d="M19 16v3"></path>
            <path d="M19 22v.01"></path>
          </svg>
        );
        break;
      case '4':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M3 21v-13l9 -4l9 4v13"></path>
          <path d="M13 13h4v8h-10v-6h6"></path>
          <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3"></path>
        </svg>
        );
        break;
      case '5':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M16 6m-5 0a5 3 0 1 0 10 0a5 3 0 1 0 -10 0"></path>
            <path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
            <path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
            <path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
            <path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path>
            <path d="M5 15v1m0 -8v1"></path>
          </svg>
        );
        break;
      case '6':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
            <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path>
            <path d="M6 14m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
            <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path>
          </svg>
        );
        break;
      default:
        break;
    }
    return icon;
  }

  render() {
    return (
      <g>
        {this.getIcon()}
      </g>
    );
  }
}
const getTooltipText = (color:any) => {
  switch (color) {
    case 'RED': return 'en rojo';
    case 'ORANGE': return 'en naranja';
    case 'YELLOW': return 'en amarillo';
    case 'GREEN': return 'en verde';
    default: return '';
  }
};
const segmentColors = {
  RED: '#E33945',
  ORANGE: '#FC9416',
  YELLOW: '#FFD700',
  GREEN: '#56B448',
};

const CustomLegend = (props: any) => {
  const { payload } = props;

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '12px' }}>
      {payload.map((entry: any, index: any) => (
        <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
          <div className='container-legend'>
            <div
              style={{
                width: '12px',
                height: '12px',
                backgroundColor: entry.color,
                marginRight: '8px',
                flexShrink: 0,
              }}
            ></div>
            <span style={{ color: entry.color, fontWeight: 'bold', fontSize:'14px' }}>{entry.value}</span>
          </div>
          <div className='container-legend-2'>
            <p style={{ color: 'black', margin: 0, fontSize:'14px', textAlign:'justify' }}>{entry.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const CustomTooltip = (props:any) => {
  const { active, payload, label }= props;
  if (active && payload && payload.length) {
    const data = payload.reverse()
    const icon = getIcon(label); 

    return (
      <div style={{maxWidth: '420px', height: 'auto', wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal',backgroundColor: 'rgba(255, 255, 255, 0.8)', fontSize: '14px', padding:'12px' }}>
        {icon}
        {data.map((entry:any, index:any) => {
            const text = getTooltipText(entry.name)
            return (
              <div key={index} style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <span style={{ marginLeft: '10px', fontSize:'14px', color: 'black' }}>
                  {entry.value}% {text}
                </span>
              </div>
            );
          })}
      </div>
    );
  }

  return null;
};

const AnalyticsBarChart: FC<AnalyticsBarChartProps> = ({
  value,
  segments,
  displayOptions
}) => {

  let data = formatData(segments, displayOptions);

  return (
    <ResponsiveContainer
      width="90%"
      className="responsive-chart"
    >
      <BarChart
        width={400}
        height={200}
        data={data}
      >
       <Tooltip
          cursor={false}
          offset={40}
          separator=": "
          labelFormatter={() => ``}
          content={<CustomTooltip />}
        />
        <Legend 
         payload={legendData}
         content={<CustomLegend />}
         wrapperStyle={{
          marginTop:'12px',
          display: 'flex',    
          flexWrap: 'wrap',  
          bottom: '-16px'
        }}
        />
        <XAxis
          padding={{ left: 16, right: 16 }}
          dataKey="value"
          tickLine={false}  tick={<CustomAxis />}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <YAxis 
          domain={[0, 100]}
          tickFormatter={(tick) => (tick > 100 ? 100 : tick)}
          ticks={[0, 100]} 
          label={{
            value: '% de escuelas',
            angle: -90, 
            position: 'insideLeft',  
            offset: 10,
            dy: 60,
          }} 
        />
        <Bar dataKey="RED" stackId="a" fill={segmentColors.RED} maxBarSize={48}>
          <LabelList fill="black" position="inside" dataKey='' />
        </Bar>
        <Bar dataKey="ORANGE" stackId="a" fill={segmentColors.ORANGE} maxBarSize={48}>
          <LabelList fill="black" position="inside" dataKey='' />
        </Bar>
        <Bar dataKey="YELLOW" stackId="a" fill={segmentColors.YELLOW} maxBarSize={48}>
          <LabelList fill="black" position="inside" dataKey='' />
        </Bar>
        <Bar dataKey="GREEN" stackId="a" fill={segmentColors.GREEN} maxBarSize={48}>
          <LabelList fill="black" position="inside" dataKey='' />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export {
  AnalyticsBarChart,
}