const LOCAL_STORAGE_PREFIX = 'cxn_';
const AUTH_USERNAME_KEY = `${LOCAL_STORAGE_PREFIX}auth_username`;
const AUTH_USER_TOKEN_KEY = `${LOCAL_STORAGE_PREFIX}auth_user_token`;
const AUTH_REFRESH_TOKEN_KEY = `${LOCAL_STORAGE_PREFIX}auth_refresh_token`;
//const API_URL = `https://api.nutrento.org`;
const API_URL = `https://api-dev.nutrento.org`;
//const API_URL = `http://localhost:3000`;
//const SERVER_URL = `https://nutrento.org`;
const SERVER_URL = `https://dev.nutrento.org`;
//const SERVER_URL = `http://localhost:3001`;

export {
  API_URL,
  SERVER_URL,
  AUTH_USERNAME_KEY,
  AUTH_USER_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
}
