import { FC } from 'react';
import { AnalyticsBarChart } from './bar-chart';

interface EvaluationCardProps {
  scores: any;
  data:any;
}

const EvaluationCard: FC<EvaluationCardProps> = ({
  scores,
  data
}) => {

  const options:any = {
    GLOBAL_SCORE: {
      id: '0',
      display: "Total"
    },
    PAE_SCORE: {
      id: '1',
      display: "Implementación de programas de alimentación escolar"
    },
    EAN_SCORE: {
      id: '2',
      display: "Implementación de la Educación Alimentaria Nutricional"
    },
    VI_SCORE: {
      id: '3',
      display: "Vigilancia de alimentos con exceso o altos en grasa saturada, azúcar y sal"
    },
    CR_SCORE: {
      id: '4',
      display: "Coordinación con instituciones"
    },
    FN_SCORE: {
      id: '5',
      display: "Gestión de recursos financieros"
    },
    LAS_SCORE: {
      id: '6',
      display: "Espacios y aplicación de normativas para cuidar la salud nutricional de niños y niñas"
    }
  }

  return (
    <div className='container-chart'>
      <div className='container-chart-data'>
        <table>
        <tbody>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 14m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                  <path d="M12 11v-6a2 2 0 0 1 2 -2h2v1a2 2 0 0 1 -2 2h-2"></path>
                  <path d="M10 10.5c1.333 .667 2.667 .667 4 0"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.PAE_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
                  <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
                  <path d="M3 6l0 13"></path>
                  <path d="M12 6l0 13"></path>
                  <path d="M21 6l0 13"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.EAN_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M14.473 17.659a8.897 8.897 0 0 1 -2.473 .341c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                  <path d="M19 16v3"></path>
                  <path d="M19 22v.01"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.VI_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 21v-13l9 -4l9 4v13"></path>
                  <path d="M13 13h4v8h-10v-6h6"></path>
                  <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.CR_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16 6m-5 0a5 3 0 1 0 10 0a5 3 0 1 0 -10 0"></path>
                  <path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
                  <path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
                  <path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
                  <path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path>
                  <path d="M5 15v1m0 -8v1"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.FN_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                  <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path>
                  <path d="M6 14m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                  <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.LAS_SCORE.display}`}</p></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}>
        <p style={{textAlign: 'center', fontSize: '16px', lineHeight: 1.5, marginBottom: '8px', fontWeight: 700, color: '#511013'}}>{'NIVEL DE ACCIONES O ELEMENTOS QUE FOMENTAN AMBIENTES ALIMENTARIOS ESCOLARES SALUDABLES'}</p>
        {(scores &&
          <AnalyticsBarChart
            value={scores.GLOBAL_SCORE || '-'}
            segments={scores}
            displayOptions={options}
          />
        )}
      </div>
    </div>
  );
}

export default EvaluationCard;